@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

:root {
    --oxford-blue: #011936;
    --charcoal: #465362;
    --cambridge-blue: #82A3A1;
    --olivine: #9FC490;
    --tea-green: #C0DFA1;
    --background: #edf6f9;

}

html {
    margin: 0;
    padding: 0;
    font-size: 62,5%;
    overflow-x: hidden;
}

body {
    font-family: 'Oswald', sans-serif;
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
}

.container {
    max-width: 90rem;
    margin: 5rem auto;
    padding: 3rem;
    border-radius: 1rem;
    background-color: var(--background-color2);
    transition: all 0.3s ease;
}

.container:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}


h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 3rem;
    color: var(--oxford-blue);
    border-bottom: 2px solid var(--charcoal);
    padding-bottom: 3rem;
}

.selectionContainer,
.searchContainer {
    color: var(--oxford-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.searchContainer {
    margin-bottom: 0.5rem;
}

.searchContainer input {
    font-size: 0.8rem;
    color: var(--oxford-blue);
}

label {
    font-size: 1.2rem;
    margin-right: 1rem;
}

select,
input[type="text"] {
    color: var(--oxford-blue);
    padding: 0.8rem;
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    outline: none;
}

.filterContainer {
    color: var(--oxford-blue);
    display: flex;
    max-width: 30rem;
    gap: 0.5rem;
}

.filterContainer input {
    width: 50%;
    border: 1px solid #e0e0e0;
}

.filterContainer select,
.filterContainer input {
    padding: 0.4rem;
    font-size: 0.8rem;
}


button {
    background-color: var(--cambridge-blue);
    color: var(--oxford-blue);
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 0.6rem;
    cursor: pointer;
    margin-left: 1.5rem;
    transition: all 0.3s ease;
}

.newsItem {
    display: flex;
    align-items: center;
    margin-top: 4rem;
    border-bottom: 1px solid var(--charcoal);
    padding-bottom: 2rem;
}

.newsItem:last-child {
    border-bottom: none;
}

.newsImage {
    flex: 1;
    max-width: 30rem;
}

.newsImage img {
    max-width: 100%;
    height: 18rem;
    object-fit: cover;
    border-radius: 1rem;
}

.newsContent {
    flex: 2;
    margin-left: 2rem;
}

.newsContent .info {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: var(--charcoal);
    margin-bottom: 1.2rem;
}

.newsContent .info h5 {
    margin: 0;
    font-weight: 400;
}

.newsContent h2 {
    font-size: 1.8rem;
    color: var(--oxford-blue);
    margin-top: 0;
    min-height: 5.4rem;
    max-height: 6.4rem;
    overflow: hidden;

}

.newsContent p {
    color: var(--charcoal);
    margin: 1rem 0 1rem;
    padding-bottom: 1rem;
    font-size: 1.4rem;
    max-height: 8rem;
    overflow:hidden;
}

.newsContent a {
    background-color: var(--cambridge-blue);
    color: var(--oxford-blue);
    padding: 0.8rem 1.4rem;
    font-size: 1.3rem;
    border-radius: 0.6rem;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
}

button:hover, .newsContent a:hover {
    background-color: var(--olivine);
}

@media (min-width: 130rem) {
    h1 {
        font-size: 3rem;
    }

    .searchContainer input {
        font-size: 1.5rem;
    }

    label {
        font-size: 2.5rem;
    }

    select, button, .filterContainer select,
    .filterContainer input {
        font-size: 2rem;
    }

    .newsContent a {
        font-size: 2rem;
    }

    button, .newsContent a {
        font-size: 2rem;
    }

    .newsItem .info {
        font-size: 2.5rem;
    }

    .newsContent h2 {
        font-size: 2rem;
        max-height: 5rem;
    }

    .newsContent p {
        font-size: 1.8rem;
        max-height: 10rem;
    }

    .filterContainer {
        max-width: 60rem;
    }
}

@media (max-width: 50rem) {
    .container {
        padding: 1.3rem;
    }

    h1 {
        font-size: 1.8rem;
    }

    .searchContainer input {
        font-size: 1rem;
    }

    label {
        font-size: 1.4rem;
    }

    select, button {
        font-size: 1rem;
    }

    .newsContent a {
        font-size: 1rem;
    }

    button, .newsContent a {
        font-size: 1rem;
        padding: 1rem 1rem;
    }

    .newsItem {
        margin-top: 3.75rem;
        padding-bottom: 2.5rem;
    }

    .newsItem {
        display: flex;
        flex-direction: column;
    }

    .newsContent .info {
        justify-content: center;
        gap: 1rem;
        margin-bottom: 0.8rem;
        font-size: 1.5rem;
    }

    .newsContent {
        margin-left: 0rem;
    }

    .newsContent h2 {
        font-size: 1.5rem;
    }

    .newsContent p {
        font-size: 1rem;
    }
}

@media (max-width: 40.78125rem) {
    .container {
        padding: 1.2rem;
    }

    h1 {
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .selectionContainer {
        gap: 0rem;
        margin-bottom: 0.5rem;
    }

    .searchContainer input {
        font-size: 0.8rem;
    }

    label {
        font-size: 1.2rem;
        margin-right: 1rem;
    }

    select, button {
        flex: 0 0 28%;
        font-size: 0.8rem;
    }
    
    .newsContent a {
        margin-left: 0;
        font-size: 0.8rem;
        font-weight: 400;
    }

    button, .newsContent a {
        font-size: 0.8rem;
        padding: 0.8rem 0.8rem;
        border: none;
        border-radius: 0.6rem;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .newsItem {
        margin-top: 3rem;
        padding-bottom: 2rem;
    }

    .newsItem {
        display: flex;
        flex-direction: column;
    }

    .newsContent .info {
        justify-content: center;
        gap: 0.8rem;
        margin-bottom: 0.8rem;
        font-size: 1.5rem;
    }

    .newsContent {
        margin-left: 0rem;
    }

    .newsContent h2 {
        font-size: 1.5rem;
    }

    .newsContent p {
        font-size: 0.8rem;
    }
}

@media (max-width: 27.1875rem) {
    .container {
        padding: 0.8rem;
    }

    h1 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }

    .selectionContainer {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 0.5rem;
        justify-content: space-between;
    }

    .selectionContainer > :first-child {
        flex: 0 0 100%;
    }

    .searchContainer input {
        font-size: 0.6rem;
    }

    label {
        font-size: 1rem;
        margin-right: 0.5rem;
    }

    select, button {
        flex: 0 0 28%;
        font-size: 0.6rem;
    }

    .newsContent a {
        margin-left: 0;
        font-size: 0.6rem;
        font-weight: 400;
    }

    button, .newsContent a {
        font-size: 0.6rem;
        padding: 0.8rem 0.8rem;
        border: none;
        border-radius: 0.6rem;
        cursor: pointer;
        transition: all 0.3s ease;
    }


    .filterContainer {
        gap: 0.3rem;
    }

    .filterContainer select {
        width: 50%;
    }

    .filterContainer input {
        width: 1rem;
    }

    .newsItem {
        margin-top: 2rem;
        padding-bottom: 1rem;
    }

    .newsItem {
        display: flex;
        flex-direction: column;
    }

    .newsContent .info {
        justify-content: center;
        gap: 0.8rem;
        margin-bottom: 0.8rem;
        font-size: 1rem;
    }

    .newsContent {
        margin-left: 0rem;
    }

    .newsContent h2 {
        font-size: 1rem;
    }


    .newsContent p {
        font-size: 0.6rem;
    }

}